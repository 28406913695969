import  { Component } from "react";
export default class servicePersonnel extends Component{
    render() {
        return (
            <form>
                <h3>Total Amount</h3>
                <div className="items">
                    <label>Image</label> 
                    <input type="file" accept="image/*" className=" form-control" />
                    <div className='d-flex justify-content-between services'>
                        <input type="text" className="form-control" placeholder="Enter Name" />
                        <input type="tel" id="phone" name="phone" className="form-control" placeholder="Enter contact" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required/>
                    </div>
                    <div className='d-flex justify-content-between services'>
                        <input type="text" className="form-control" placeholder="Enter Rating" />
                        <input type="text" className="form-control" placeholder="Enter Type" />
                    </div>
                </div> 
                
            </form>
        );
    }
}