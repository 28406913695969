import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../constant";
import AddNewCheckBox from "./AddNewCheckBox";
import UserDetails from "./UserDetails";

const AddNew = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [type, setType] = useState("");
  const [date, setDate] = useState("");
  const [invoiceImage, setInvoiceImage] = useState(null);
  const [isWarranty, setIsWarranty] = useState(false);
  const [warranty_duration, setWarranty_duration] = useState("");
  const [warranty_isTBAlert, setWarranty_isTBAlert] = useState(false);
  const [isInsurance, setIsInsurance] = useState(false);
  const [insurance_duration, setInsurance_duration] = useState("");
  const [insurance_isTBAlert, setInsurance_isTBAlert] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const newItem = async () => {
    const response = await fetch(`${API}/item/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        price,
        brand,
        model,
        type,
        date,
      }),
    });
    const data = await response.json();
    console.log(data);
    return data;
  };
  const newBill = async (itemId) => {
    const formData = new FormData();
    formData.append("invoice_image", invoiceImage, invoiceImage.name);
    formData.append("item_id", itemId);
    formData.append("date", date);
    const response = await fetch(`${API}/bill/`, {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    console.log(data);
    return data;
  };

  const newWarranty = async (billId) => {
    const response = await fetch(`${API}/warranty/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        bill: billId,
        duration: warranty_duration,
        isTBAlert: warranty_isTBAlert,
      }),
    });
    const data = await response.json();
    console.log(data);
    return data;
  };

  const newInsurance = async (billId) => {
    const response = await fetch(`${API}/insurance/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        bill: billId,
        duration: insurance_duration,
        isTBAlert: insurance_isTBAlert,
      }),
    });
    const data = await response.json();
    console.log(data);
    return data;
  };

  const handleCreation = async (e) => {
    e.preventDefault();
    setIsCreating(true);
    const item = await newItem();
    const bill = await newBill(item.id);
    if (isWarranty) {
      await newWarranty(bill.id);
    }
    if (isInsurance) {
      await newInsurance(bill.id);
    }
    setIsCreating(false);
    history.push("/userViewItems");
  };
  //const history=useHistory();
  return (
    <form onSubmit={handleCreation} className="addNew">
      <UserDetails />
      <h3>Add new item </h3>
      <div className="form-group">
        <div>
          <label>Item Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter item name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      {/* <div>
        <label>Item's Image</label>
        <input type="file" accept="image/*" className=" form-control" />
      </div> */}

      <div className="form-group">
        <label>Price</label>
        <input
          type="number"
          className="form-control"
          placeholder="Enter price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Brand</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter brand"
          value={brand}
          onChange={(e) => setBrand(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Model</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter model"
          value={model}
          onChange={(e) => setModel(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Type</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter type"
          value={type}
          onChange={(e) => setType(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Date</label>
        <input
          type="date"
          className="form-control"
          placeholder="Enter date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </div>
      <div>
        <label>Invoice Image</label>
        <input
          type="file"
          accept="image/*"
          className=" form-control"
          onChange={(e) => setInvoiceImage(e.target.files[0])}
        />
      </div>
      <AddNewCheckBox
        showData={isWarranty}
        setShowData={setIsWarranty}
        duration={warranty_duration}
        setDuration={setWarranty_duration}
        isAlert={warranty_isTBAlert}
        setIsAlert={setWarranty_isTBAlert}
        labelName1="Warranty"
      />
      <AddNewCheckBox
        showData={isInsurance}
        setShowData={setIsInsurance}
        duration={insurance_duration}
        setDuration={setInsurance_duration}
        isAlert={insurance_isTBAlert}
        setIsAlert={setInsurance_isTBAlert}
        labelName1="Insurance"
      />
      <button
        disabled={isCreating}
        type="submit"
        className="btn btn-primary btn-block"
      >
        Submit
      </button>
    </form>
  );
};
export default AddNew;
