import history from "./history";
import UserDetails from "./UserDetails";
export default function UserView() {
  return (
    <form className="userView">
      <h3>User View</h3>
      <UserDetails />
      <div className="d-flex flex-column bd-highlight mb-3">
        <button
          className="btn btn-success btn-color btn-block"
          onClick={() => history.push("/userViewItems")}
        >
          Items
        </button>
        <br />
        <button
          className="btn btn-success btn-color btn-block"
          onClick={() => {
            history.push('/userViewServices')
          }}
        >
          Services
        </button>
      </div>
    </form>
  );
}
