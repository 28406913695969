import React from 'react';
import CheckBox from "./CheckBox";
import CheckBox2 from "./CheckBox2";

import {  Route, } from "react-router-dom";
class SignUp extends React.Component {
    constructor() {
    super();
    this.state = {
      input: {},
      errors: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;

    this.setState({
      input
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if(this.validate()){
        //console.log(this.state);

        let input = {};
        input["name"] = "";
        input["email"] = "";
        input["password"] = "";
        input["confirm_password"] = "";
        this.setState({input:input});

        alert('Hurrah! Thanks for registering' ,window.location="/sign-in");
    }
  }

  validate(){
      let input = this.state.input;
      let errors = {};
      let isValid = true;


      // if (!input["email"]) {
      //   isValid = false;
      //   errors["email"] = "Please enter your email Address.";
      // }

      // if (typeof input["email"] !== "undefined") {

      //   var pattern = new RegExp("[a-z0-9]+@[a-z]+\.[a-z]{2,3}");
      //   if (!pattern.test(input["email"])) {
      //     isValid = false;
      //     errors["email"] = "Please enter valid email address.";
      //   }
      // }

      if (!input["password"]) {
        isValid = false;
        errors["password"] = "Please enter your password.";
      }

      if (!input["confirm_password"]) {
        isValid = false;
        errors["confirm_password"] = "Please enter your confirm password.";
      }

      if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {

        if (input["password"] !== input["confirm_password"]) {
          isValid = false;
          errors["password"] = "Passwords don't match.";
        }
      }

      this.setState({
        errors: errors
      });

      return isValid;
  }

  render() {
    return (
      <div >
          <h3>Register </h3>

      <div>
        <form onSubmit={this.handleSubmit}>

          <div className="form-group">
            <div className="form-group">
                <label>First name</label>
                <input type="text" className="form-control"placeholder="First name" />
            </div>

            <div className="form-group">
                <label>Last name</label>
                <input type="text" className="form-control" placeholder="Last name" />
            </div>

            <div className="text-danger">{this.state.errors.name}</div>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address:</label>
            <input
              type="email" className="form-control"
              placeholder="Enter email"
              id="email" />
          </div>

            <div className="form-group">
                <label>Phone Number</label>
                <input type="tel" id="phone" name="phone" className="form-control" placeholder="phone" pattern="(0|91)?[7-9][0-9]{9}" required/>
            </div>


            <div className="form-group">
                    <label>Address</label>
                    <input type="address" className="form-control" placeholder="Enter address1" />
                    <input type="address" className="form-control" placeholder="Enter address2" />
                </div>

                <div className="form-group">
                    <label>City</label>
                    <input type="text" className="form-control" placeholder="City" />
                </div>

                <div className="form-group">
                    <label>State</label>
                    <input type="text" className="form-control" placeholder="State" />
                </div>

                <div className="form-group">
                    <label>Country</label>
                    <input type="text" className="form-control" placeholder="Country" />
                </div>

                <div className="form-group">
                    <label>Pincode</label>
                    <input type="pincode" className="form-control" placeholder="Pincode" />
                </div>



          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              name="password"
              value={this.state.input.password}
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter password"
              id="password" />

              <div className="text-danger">{this.state.errors.password}</div>
          </div>

          <div className="form-group">
            <label htmlFor="password">Confirm Password:</label>
            <input
              type="password"
              name="confirm_password"
              value={this.state.input.confirm_password}
              onChange={this.handleChange}
              className="form-control"
              placeholder="Enter confirm password"
              id="confirm_password" />

              <div className="text-danger">{this.state.errors.confirm_password}</div>
          </div>
          <div className="signUpPersonnel">
            <div id="root">
              <Route  path="/" component={CheckBox} />
            </div>

          </div>

          <div className="signUpPersonnel">
            <div id="root">
              <Route  path="/" component={CheckBox2} />
            </div>

          </div>

          <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
                <p className="forgot-password text-right">
                    Already registered <a href={"/sign-in"}>sign in?</a>
                </p>

        </form>
      </div>
      </div>
    );
  }
}

export default SignUp;
