import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { API } from "../constant";
import { UserDispatchContext, UserContext } from "../context/user";

const Login = () => {
  const history = useHistory();
  const setUser = useContext(UserDispatchContext);
  const userDetails = useContext(UserContext);
  const handleLogin = (e) => {
    e.preventDefault();
    setTimeout(() => {
      history.push("/userView");
    }, 1000);
  };

  useEffect(() => {
    console.log(userDetails);
    if (userDetails) {
      console.log(userDetails);
      history.push("/userView");
    }
  }, [userDetails, history]);

  const responseGoogle = async (response) => {
    console.log(response);
    if (response.accessToken) {
      console.log(response.accessToken);
      const res = await fetch(`${API}/rest-auth/google/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_token: response.accessToken,
        }),
      });

      const data = await res.json();
      if (data.key) {
        setUser(response.profileObj);
      }
    } else {
      return alert("Something went wrong, please try again.");
    }
  };

  return (
    <form onSubmit={handleLogin} className="">
      <h3>Sign In</h3>

      <GoogleLogin
        clientId="511252702005-ljkiojusq2kdb0dao5d8g6vcah2g1qsl.apps.googleusercontent.com"
        buttonText="Login with Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        // cookiePolicy={"single_host_origin"}
      />
      <div className="form-group">
        <label>Email address</label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter email"
        />
      </div>

      <div className="form-group">
        <label>Password</label>
        <input
          type="password"
          className="form-control"
          placeholder="Enter password"
        />
      </div>

      <div className="form-group">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
          />
          <label className="custom-control-label" htmlFor="customCheck1">
            Remember me
          </label>
        </div>
      </div>

      <button type="submit" className="btn btn-primary btn-block">
        Submit
      </button>
      <p className="forgot-password text-right">Forgot password?</p>
      <p className="forgot-password text-right">
        New Member? <a href={"/sign-up"}>Register</a>
      </p>
    </form>
  );
};

export default Login;
