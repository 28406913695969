import React, { useEffect, useState } from "react";
import { API } from "../constant";
import UserDetails from "./UserDetails";
import "../styles/user-view-items.css";
import {AiOutlineCheck, AiOutlineClose} from 'react-icons/ai'

const UserViewServices = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(`${API}/serviceperson/`)
      .then((res) => res.json())
      .then((data) => {
        setItems(data);
        setLoading(false);
      });
  }, []);


  if (loading) return <h1>Loading</h1>;
  return (
    <form>
      <h3>Services</h3>
      <UserDetails />
      <div className="d-flex justify-content-between btns">
        <button className="btn btn-success ">Filters</button>
        {/* <button
          className="btn btn-success "
          onClick={() => history.push("/AddNew")}
        >
          Add New Item
        </button> */}
      </div>
      <div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Hourly Rate</th>
              <th scope="col">Active Status</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>{item.service_type}</td>
                <td>{item.hourly_rate}</td>
                <td>{item.is_active ? (<AiOutlineCheck/>) : (<AiOutlineClose />)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default UserViewServices;
