import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "./components/login.component";
import SignUp from "./components/signup.component";
import userView from "./components/userView";
import userViewServices from "./components/userViewServices";
import UserViewItems from "./components/userViewItems";
import AddNew from "./components/AddNew";
import servicePersonnel from "./components/servicePersonnel";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/sign-in" component={Login} />
              <Route path="/userView" component={userView} />
              <Route path="/userViewItems" component={UserViewItems} />
              <Route path="/userViewServices" component={userViewServices} />
              <Route path="/AddNew" component={AddNew} />
              <Route path="/servicePersonnel" component={servicePersonnel} />
              <div className="signUp">
                <div>
                  <Route path="/sign-up" component={SignUp} />
                </div>
              </div>
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
