import history from "./history";
import React, { useEffect, useState } from "react";
import { API } from "../constant";
import UserDetails from "./UserDetails";
import "../styles/user-view-items.css";

const UserViewItems = () => {
  const [items, setItems] = useState([]);
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(`${API}/item/`)
      .then((res) => res.json())
      .then((data) => {
        setItems(data);
      });
  }, []);

  useEffect(() => {
    if (items.length > 0 && bills.length === 0) {
      fetch(`${API}/bill/`)
        .then((res) => res.json())
        .then((data) => {
          setItems(
            items.map((item) => {
              const bill = data.find((x) => x.item_id === item.id) || {
                invoice_image: "",
              };
              const newItem = {
                ...item,
                invoice_image:
                  "https://res.cloudinary.com/cmrit/" + bill.invoice_image,
              };
              return newItem;
            })
          );
          setBills(data);
          setLoading(false);
        });
    }
  }, [items, bills]);

  if (loading) return <h1>Loading</h1>;
  return (
    <form>
      <h3>Items</h3>
      <UserDetails />
      <div className="d-flex justify-content-between btns">
        <button className="btn btn-success ">Filters</button>
        <button
          className="btn btn-success "
          onClick={() => history.push("/AddNew")}
        >
          Add New Item
        </button>
      </div>
      <div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Invoice</th>
              <th scope="col">Name</th>
              <th scope="col">Price</th>
              <th scope="col">Brand</th>
              <th scope="col">Model</th>
              <th scope="col">Type</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>
                  <img
                    className="invoice-image"
                    src={`${item.invoice_image}`}
                    alt="invoice"
                  />
                </td>
                <td>{item.name}</td>
                <td>{item.price}</td>
                <td>{item.brand}</td>
                <td>{item.model}</td>
                <td>{item.type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default UserViewItems;
