import React, { Component } from "react";
import ReactDOM from 'react-dom';

export default class CheckBox2 extends Component{
    constructor() {
      super();
      this.state = {isChecked: false};
      this.handleChecked = this.handleChecked.bind(this); // set this, because you need get methods from CheckBox
    }

    handleChecked () {
      this.setState({isChecked: !this.state.isChecked});
    }

    render(){
      var txt;

      if (this.state.isChecked) {
        txt = <button type="submit" className="btn btn-success btn-color btn-block">Service Shop</button>
      }

      return <div>
         <input type="checkbox" onChange={ this.handleChecked }/>
         <label htmlFor="checkBox">Self Employeed?</label>
          <p>{txt}</p>
      </div>
    }
  }

  ReactDOM.render(
    <CheckBox2/>,
    document.getElementById('root')
  );
