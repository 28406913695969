import React, { useContext } from "react";
import { UserContext } from "../context/user";
import "../styles/UserDetails.css";

const UserDetails = () => {
  const userDetails = useContext(UserContext);
  return (
    userDetails && (
      <div className="user-details">
        <p>Welcome, {userDetails.name}</p>
        <img src={userDetails.imageUrl} alt="User pic" />
      </div>
    )
  );
};

export default UserDetails;
