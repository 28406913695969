import React from "react";
const AddNewCheckBox = ({
  labelName1,
  duration,
  setDuration,
  showData,
  setShowData,
  isAlert,
  setIsAlert,
}) => {
  return (
    <>
      <div className="form-check form-switch">
        <input
          className="form-check-input float-end"
          onClick={(e) => setShowData(e.target.checked)}
          type="checkbox"
          id="flexSwitchCheckDefault"
        />
        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
          {labelName1}
        </label>
      </div>
      {showData && (
        <>
          <div className="form-group">
            <label>Duration</label>
            <input
              type="duration"
              className="form-control"
              placeholder="Enter duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          </div>
          <div className="form-check form-switch signUpPersonnel">
            <input
              className="form-check-input float-end"
              type="checkbox"
              id="flexSwitchCheckDefault"
              onClick={(e) => setIsAlert(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
              Alert
            </label>
          </div>
        </>
      )}
    </>
  );
};
export default AddNewCheckBox;
